import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { MSAL_INSTANCE, MsalService } from '@azure/msal-angular';
import { MSALInstanceFactory } from 'app/authentication/authentication.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDatepicker, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlanDateRangeComponent } from './components/plan-date-range/plan-date-range.component';
import { TechnicalAreaComponent } from './components/technical-area/technical-area.component';
import { PlanScoresGoalsComponent } from './components/plan-scores-goals/plan-scores-goals.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GanttChartComponent } from './components/gantt-chart/gantt-chart.component';
import { PlanCreationSuccessLandingPageComponent } from './components/plan-creation-success-landing-page/plan-creation-success-landing-page.component';
import { UpdatedScoreComponent } from './components/updated-score/updated-score.component';
import { JeeRecActionsComponent } from './components/jee-rec-actions/jee-rec-actions.component';
import { CommonAccordionComponent } from './components/common-accordion/common-accordion.component';
import { ImportIhrActionsComponent } from './components/import-ihr-actions/import-ihr-actions.component';
import { DropdownModule } from 'primeng/dropdown';
import { AddActionsToSpComponent } from './components/add-actions-to-sp/add-actions-to-sp.component';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { SortingComponent } from './components/sorting/sorting.component';
import { DeleteActionComponent } from './components/delete-action/delete-action.component';
import { AddNbwActionsToOpComponent } from './components/add-nbw-actions-to-op/add-nbw-actions-to-op.component';
import { DragDirective } from './directives/dragAndDrop.directive';
import { ActivatePlanComponent } from './components/activate-plan/activate-plan.component';
import { NgxCurrencyModule } from '@ngodings/ngx-currency';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { SafePipe } from './pipes/safe.pipe';
import { UnauthorizedAccessComponent } from './components/unauthorized-access/unauthorized-access.component';
import { ClonePlanComponent } from './components/clone-plan/clone-plan.component';
import { UploadPlanComponent } from './components/upload-plan/upload-plan.component';
import { AddActionAdditionalAssessmentComponent } from './components/add-action-additional-assessment/add-action-additional-assessment.component';
import { ImportActionTableComponent } from './components/import-action-table/import-action-table.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { MapComponent } from './components/map/map.component';
import { NotificationLandingPageComponent } from './components/notification-landing-page/notification-landing-page.component';
import { PendingSALinkingComponent } from './components/pending-salinking/pending-salinking.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { ImplementationPercentModalComponent } from './components/implementation-percent-modal/implementation-percent-modal.component';
import { ImportActionSummaryComponent } from './components/import-action-summary/import-action-summary.component';
import { NewSAReviewComponent } from './components/new-sareview/new-sareview.component';
import { EditPlanDateRangeComponent } from './components/edit-plan-date-range/edit-plan-date-range.component';
import { PlanDateRangeUserComponent } from './components/plan-date-range-user/plan-date-range-user.component';




@NgModule({
  declarations: [
    //components
  
    PlanDateRangeComponent,
    TechnicalAreaComponent,
    PlanScoresGoalsComponent,
    GanttChartComponent,
    PlanCreationSuccessLandingPageComponent,
    UpdatedScoreComponent,
    JeeRecActionsComponent,
    CommonAccordionComponent,
    ImportIhrActionsComponent,
    AddActionsToSpComponent,
    TablePaginationComponent,
    SortingComponent,
    DeleteActionComponent,
    AddNbwActionsToOpComponent,
    DragDirective,
    ActivatePlanComponent,
    CommonModalComponent,
    SafePipe,
    UnauthorizedAccessComponent,
    ClonePlanComponent,
    UploadPlanComponent,
    AddActionAdditionalAssessmentComponent,
    ImportActionTableComponent,
    MapComponent,
    HomeContentComponent,
    NotificationLandingPageComponent,
    UploadPlanComponent,
    AddActionAdditionalAssessmentComponent,
    ImportActionTableComponent,
    MapComponent,
    HomeContentComponent,
    CustomDatePipe,
    PendingSALinkingComponent,
    ImportActionSummaryComponent,
    NewSAReviewComponent,
    ImplementationPercentModalComponent,
    EditPlanDateRangeComponent,
    PlanDateRangeUserComponent,

  ],  
  imports: [
    //modules
    TranslateModule.forChild(),
    CommonModule,
    NgbModule,
    DropdownModule,
    NgbDatepicker,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MultiSelectModule,
    NgxCurrencyModule
  ],
  exports: [
    NgbModule,
    NgbDatepicker,
    FormsModule,
    TechnicalAreaComponent,
    PlanScoresGoalsComponent,
    ReactiveFormsModule,
    PlanDateRangeComponent,
    GanttChartComponent,
    NgSelectModule,
    PlanCreationSuccessLandingPageComponent,
    UpdatedScoreComponent,
    JeeRecActionsComponent,
    CommonAccordionComponent,
    TablePaginationComponent,
    MultiSelectModule,
    SortingComponent,
    NgxCurrencyModule,
    DragDirective,
    ImportActionTableComponent,
    HomeContentComponent,
    MapComponent,
    ImportActionTableComponent,
    HomeContentComponent,
    MapComponent,
    CustomDatePipe,
    NotificationLandingPageComponent,
    NewSAReviewComponent,
    EditPlanDateRangeComponent
  ],
  providers: [
    MsalService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    DatePipe,
    CustomDatePipe,
    CurrencyPipe
  ]
})
export class SharedModule { }
