<div *ngIf="userPermission > 0" class="left-sidebar" id="left-sidebar">
    <button class="btn btn-lg open-close-btn" *ngIf="isSideButtonActive" (click)="toggleLeftSidebar()"><i class="bi bi-chevron-left"></i></button>
    <div class="menu-wrapper">
        <ul class="sidebar-menu-list">
            <ng-container *ngFor="let item of sidebarMenu">
                <li *ngIf="item.permission && item.type !== 'email'" [ngClass]="'bi-'+ item.icon +' '+ item?.badgeClass" [class.active]="item.active"
                    class="disabled" [routerLink]="item?.isItem ? item.path : []"
                    (click)="setActiveTab(item?.path)">
                    <hr *ngIf="!item.isItem" [ngClass]="item.itemClass">
                    <span><i  [ngClass]="item.icon" class="bi me-2"></i></span>
                    <span  *ngIf="item.translatedTitle" class="menu-title">{{item.translatedTitle | translate}}</span>
                </li>
                <ng-container *ngIf="item.permission && item.type == 'email'">
                    <li (click)="downloadUserGuide()">
                        <span><i class="bi bi-cloud-arrow-down-fill me-2"></i></span>
                        <span class="menu-title">{{'User Guide' | translate}}</span>
                    </li>
        
                    <li [ngClass]="'bi-'+ item.icon +' '+ item?.badgeClass"
                    class="disabled" (click)="redirectToMail()">
                    <span><i  [ngClass]="item.icon" class="bi me-2"></i></span>
                    <span  *ngIf="item.translatedTitle" class="menu-title">{{item.translatedTitle | translate}}</span>
                </li>
                </ng-container>
                

            </ng-container>

        </ul>
        <!-- <ul class="sidebar-menu-list sidebar-bottom-list">
            <li>
                <span><i class="bi bi-gear-fill me-2"></i></span><span class="menu-title">System
                    Management</span>
            </li>
        </ul> -->
    </div>
</div>
<!-- <div *ngIf="showSubSideBar" class="left-sub-sidebar" [ngClass]="{'compact-sub-sidebar-left': isSidebarCompact == true}" id="left-sub-sidebar"> -->
    <!-- <button class="btn btn-lg open-close-btn" (click)="toggleSubLeftSidebar()"><i
            class="bi bi-chevron-left"></i></button> -->
    <!-- <div class="menu-wrapper">
        <ul class="sidebar-menu-list">
            <li *ngFor = "let sideMenu of sidebarSubMenu" [class.active]="sideMenu.active"
            [ngClass]="sideMenu.badgeClass" [routerLink]="sideMenu?.isItem ? sideMenu.path : []"
            (click)="setActiveSubTab(sideMenu?.path)">
                <span class="menu-title">{{sideMenu.title}}</span>
            </li>
        </ul>
    </div>
</div> -->
