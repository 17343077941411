import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader/loader.service';
import { LoaderState } from '../../interfaces/loader.model';
@Component({
  selector: 'app-loading',
  template: `<div>
  <div *ngIf="show" id="loader-wrapper" class="loader-overlay">
    <div *ngIf="show" id="loader" class="loader">
    <img class="loading" src="../../../../assets/images/loading.png" />
    </div>
  </div>
</div>`,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription;
  constructor(private loaderService: LoaderService) { }
  ngOnInit(): void {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
