import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LoggedInUser, NotificationModel } from 'app/shared/interfaces/user.model';
import { LoginService } from 'app/shared/services/login/login.service';
import { CommonHttpService } from 'app/shared/services/common-http/common-http.service';
import { SignalRService } from 'app/shared/services/signalr/signalr.service';
import { UserService } from 'app/shared/services/user/user.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { notificationReloadInterval } from 'app/shared/constants';
import { LoginInfo } from 'app/shared/interfaces/loginInfo.model';
import { environment } from 'environments/environment';
import { MessageService } from 'app/shared/services/message/message.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  loggedInUser: LoggedInUser;
  profileImage : any;
  loggedInCheck = new BehaviorSubject<boolean>(false);
  isLoggedIn: boolean = false;


  notifications : NotificationModel [] = [];

  isUAT = environment.instance === 'UAT';
  hasAllNotifications : boolean = false;
  notificationCall: NodeJS.Timeout;

  constructor(
    private http : CommonHttpService,
    private router: Router,
    private msalService: MsalService,
    private userService:UserService,
    private utilService : UtilsService , private loginService : LoginService,
    private messageService : MessageService
    // private signalR : SignalRService
    )
  {
  }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      localStorage.getItem('logged_in_user') || '{}'
    );
    if(Object.keys(this.loggedInUser).length > 0) {
      this.loginService.loginTrigger.next(true);
    }
    else{
      this.loginService.loginTrigger.next(false);
    }
    this.loginService.loginTrigger.subscribe(val => {
      this.isLoggedIn = val;
      if(val){
        this.setUserProfile();
      }
    })
  }

  setUserProfile(){
    this.loggedInUser = JSON.parse(
      localStorage.getItem('logged_in_user') || '{}'
    );    
    this.getUserProfilePic();

    this.userService.profilePictureChangedSubject.subscribe((image:string) => {
      const loggedInUser = JSON.parse(
        localStorage.getItem('logged_in_user') || '{}'
      );
      this.loggedInUser.profilePicture = image;
      loggedInUser.profilePicture = image;
      localStorage.setItem('logged_in_user', JSON.stringify(loggedInUser));
      this.getUserProfilePic();
    })

    this.userService.profileDetailsChangedSubject.subscribe((profileDetails:any) => {
      const loggedInUser = JSON.parse(
        localStorage.getItem('logged_in_user') || '{}'
      );
      this.loggedInUser.firstName = profileDetails.firstName;
      this.loggedInUser.lastName = profileDetails.lastName;
      this.loggedInUser.languageId = profileDetails.preferredLanguageId;
      loggedInUser.firstName = profileDetails.firstName;
      loggedInUser.lastName = profileDetails.lastName;
      loggedInUser.displayName = profileDetails.firstName + '' + profileDetails.lastName;
      loggedInUser.languageId = profileDetails.preferredLanguageId;
      localStorage.setItem('logged_in_user', JSON.stringify(loggedInUser));
    })

    // this.signalR.startConnection(this.loggedInUser.primaryEmail);

    this.getNotifications();

    this.notificationCall = setInterval(() =>{
    const loginInfo: LoginInfo = this.utilService.getToken() as LoginInfo;
    if(!loginInfo || !loginInfo.accessToken || !loginInfo.idToken){
      clearInterval(this.notificationCall);
      return;
    }
     this.getNotifications()}, notificationReloadInterval);

    // this.signalR.receiveNotificationSubject.subscribe((value : NotificationModel[]) => {  
      
    //   if(value.findIndex(x => x.n_type == 8) != -1)
    //   {
    //     this.getNotifications();
    //   }
    //   else
    //   {
    //     this.notifications.unshift(...value);
    //     while(!this.hasAllNotifications && this.notifications.length > 10) this.notifications.pop(); 
    //   }
    // });
  }

  logoutUser() {
    this.msalService.logoutPopup({
      mainWindowRedirectUri: '/login',
    });
    localStorage.clear();
    this.loginService.loginTrigger.next(false)
    this.loginService.loginClick.next(false)
    // this.router.navigate(['/dashboard']);
  }

  redirectToEditProfile(){
    this.router.navigate(['/home/my-profile-edit'])
  }

  toLocalDate(date : any){
    return moment.utc(date).local();
  }

  loginUser(){
    this.loginService.loginClick.next(true);
  }

  getUserProfilePic(){
    if (this.loggedInUser.profilePicture){
      this.utilService.getImage(this.loggedInUser.profilePicture).subscribe(data => {
        this.profileImage = data;
      })
    }
  }

  getUnreadCount() : number {
    return this.notifications.filter(notification => !notification.is_read).length;
  }

  getNotifications(getAll : boolean = false) {
    if(!this.hasAllNotifications) {

      this.http.get<{userRole : number, notifications : NotificationModel []}>({
        uri: "/Notification/GetUserNotifications?showAll="+getAll,
        showToastr : false
      })
      .subscribe((data: {userRole : number, notifications : NotificationModel []}) => {
        if(data.userRole){
          const currRole : LoggedInUser = JSON.parse(
            localStorage.getItem('logged_in_user') || '{}'
          );
          if(!currRole || !currRole.primaryEmail || currRole.roleId !== data.userRole){
            this.messageService.showErrorMessage('roleChangedReLogin')
            this.router.navigateByUrl('/login');
          }
        }
        this.notifications = data.notifications;
        if(getAll) this.hasAllNotifications = true;
      });

    }
  }

  markNotificationAsRead(index : number) {
    var obj : any = {};
    if(index >= 0) 
    {
      // if(this.notifications[index].is_read) return;
      if(this.notifications[index].n_type ==1 ){
        this.utilService.notificationData.next(this.notifications[index]);
      }
      obj = { notificationId : this.notifications[index].n_id };
    }
    else
    {
      obj = { markAllAsRead : true }
    }
    this.http.post<boolean, any>({
      uri: "/Notification/MarkNotificationAsRead",
      object : obj 
    })
    .subscribe((result: boolean) => {    
      if(result && index >= 0) {
        this.notifications[index].is_read = true;
        if(this.notifications[index].n_type ==1 ){
          this.router.navigateByUrl('home/notification');
          // this.router.navigateByUrl('userManagement/pendingInvitations');
        }
        else if(this.notifications[index].n_type == 2 || this.notifications[index].n_type == 9){
          this.router.navigateByUrl(`review/${this.notifications[index].p_id}/${this.notifications[index].r_id}`);
        }
        else if(this.notifications[index].n_type == 6)
        {
          this.router.navigateByUrl(`review/${this.notifications[index].pr_id}/${this.notifications[index].r_id}`);
        }
        else if(this.notifications[index].n_type == 3){
          this.router.navigateByUrl('userManagement/pendingInvitations');
        }else if(this.notifications[index].n_type == 8){
          this.router.navigateByUrl(`home/Pending-Strategic-Actions/${this.notifications[index].sp_id}`);
        }
        // this.router.navigateByUrl('plan/CountryPlans');
      }
      else if(result) {
        this.notifications.forEach(x => x.is_read = true);
      }
    });


  }

  redirectToDefaultPage(){
    if(this.isLoggedIn){
      if(this.loggedInUser.roleId !== 6){
        this.router.navigate(['home']);
      }else{
        this.router.navigate(['SharedPlans']);
      }
    }
  }

  downloadUserGuide() {
    this.http.downloadResource({uri: `/User/GetUserGuide`}).subscribe((data : any) => {
      console.log(data);
      var blob = new Blob([data], {type: 'application/pdf'});
      var blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = this.getUserGuideName()
      a.click();
    }
    )
  }

  getUserGuideName(): string {
    const loggedInUser : LoggedInUser = JSON.parse(localStorage.getItem('logged_in_user') || '{}');4
    switch(loggedInUser.roleId){
      case 1: return 'eNAPHS manual-System Admin-v1.0.pdf';
      case 1: return 'eNAPHS manual-Regional Admin-v1.0.pdf';
      case 3: return 'eNAPHS manual-Country Admin-v1.0.pdf';
      case 4: return 'eNAPHS manual-Country User-v1.0.pdf';
      case 5: return 'eNAPHS manual-Secretariat-v1.0.pdf';
      case 6: return 'eNAPHS manual-Global Viewer-v1.0.pdf';
      case 7: return 'eNAPHS manual-Country Viewer-v1.0.pdf';
      default: return '';
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.notificationCall);
  }
}
