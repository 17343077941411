import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginInfo } from '../../interfaces/loginInfo.model';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private router: Router,
              private utilsService: UtilsService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const loginInfo: LoginInfo = this.utilsService.getToken() as LoginInfo;
      if (!loginInfo || !loginInfo.accessToken || !loginInfo.idToken) {
      this.router.navigate(['/login'], { queryParams : { redirect : state.url}}).then(() => {
        window.location.reload();
      });
      return false;
    }
    return true;
  }

}
