import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
// import { AuthenticationResult, InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { UserService } from 'app/shared/services/user/user.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { RoleList, Roles } from 'app/shared/constants/role.constant';
import { LoginInfo } from 'app/shared/interfaces/loginInfo.model';
import { Role } from 'app/shared/interfaces/role.model';
import { LoggedInUser } from 'app/shared/interfaces/user.model';
import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
} from '@azure/msal-browser';
import { LoginService } from 'app/shared/services/login/login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly destroying$ = new Subject<void>();
  isLoggedIn = false;
  loginError = false;
  loginErrorMessage!: string;
  allRoles = Roles;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private activatedRoute : ActivatedRoute,
    private modalService : NgbModal
  ) {}

  ngOnInit(): void {
    this.modalService.dismissAll();
    this.loginService.loginTrigger.next(false);
    // localStorage.setItem('logged_in_user', '');
    // localStorage.setItem('login_info', '');
    this.prerequisite();
    this.loginService.loginClick.subscribe((val) => {
      if (val) {
        this.loginPopup();
      } else {
        localStorage.setItem('logged_in_user', '');
        localStorage.setItem('login_info', '');
      }
    });
  }

  prerequisite(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  async loginPopup(): Promise<void> {
    // this.authService.loginPopup({
    //   prompt: 'select_account',
    //   ...this.msalGuardConfig.authRequest
    // } as PopupRequest)
    //   .subscribe(async (response: AuthenticationResult) => {
    //     if (response.accessToken && response.idToken) {
    //       await this.saveLoggedInUserInfo(response);
    //       this.router.navigate(['home']);
    //     }
    //     this.authService.instance.setActiveAccount(response.account);
    //   });
    this.authService
      .loginPopup({
        prompt: 'select_account',
        ...this.msalGuardConfig.authRequest,
      } as PopupRequest)
      .subscribe(async (response: AuthenticationResult) => {
        if (response.accessToken && response.idToken) {
          await this.saveLoggedInUserInfo(response);
        }
        this.authService.instance.setActiveAccount(response.account);
      });
  }

  async saveLoggedInUserInfo(response: AuthenticationResult): Promise<void> {
    const loginInfo: LoginInfo = {
      accessToken: response.accessToken,
      idToken: response.idToken,
    };
    localStorage.setItem('login_info', JSON.stringify(loginInfo));
    localStorage.setItem(
      'expireOn',
      response.expiresOn ? response.expiresOn.toString() : new Date().toString()
    );
    try {
      // this.userService.saveUserLoginActivity('login');
      // will get logged In User Details and Save in local Storage
      const loggedInUser: LoggedInUser =
        await this.userService.getLoggedInUserDetails();
      this.isLoggedIn = true;
      // get user role
      const roleList = RoleList;
      const role: Role = roleList.find(
        (r) => r.roleId === loggedInUser.roleId
      ) as Role;
      loggedInUser.roleDescription = role.description;
      loggedInUser.userRole = role.role;
      if (!loggedInUser.role) {
        loggedInUser.role = role;
      }
      // if (loggedInUser.roleId === 6) {
      //   loggedInUser.isReadOnly = true;
      // }
      // this.utilsService.loadLanguages();
      localStorage.setItem('logged_in_user', JSON.stringify(loggedInUser));
      //this is for the header in login and after the login
      this.loginService.loginTrigger.next(this.isLoggedIn);
      // if (loggedInUser.languageId) {
      //   this.utilsService.setLanguage(loggedInUser.languageId);
      // }
      // navigate to home
      //if there is no redirect url
      let redirectUrl = '';
      this.activatedRoute.queryParamMap.subscribe(params => {
        redirectUrl = params.get('redirect') ?? '';
      })

      if(redirectUrl.trim().length !== 0){
        this.router.navigate([redirectUrl]);
      }else{
        this.router.navigate(['home']);

        // if (
        //   loggedInUser.roleId === this.allRoles.SystemAdmin.roleId ||
        //   loggedInUser.roleId === this.allRoles.RegionalAdmin.roleId ||
        //   loggedInUser.roleId === this.allRoles.CountryAdmin.roleId ||
        //   loggedInUser.roleId === this.allRoles.CountryUser.roleId ||
        //   loggedInUser.roleId === this.allRoles.Secretariat.roleId ||
        //   loggedInUser.roleId === this.allRoles.CountryViewer.roleId
        // ) {
        //   this.router.navigate(['home']);
        // } else {
        //   this.router.navigate(['SharedPlans']);
        // }
      }
    } catch (error: any) {
      if (error.error) {
        this.isLoggedIn = true;
        // this.toast.errorToast(error.error.title);
        this.loginErrorMessage = error.error.title;
        this.loginError = true;
      }
    }
  }

  loginUser(){
    this.loginService.loginClick.next(true);
  }

  ngOnDestroy(): void {
    this.destroying$.next();
    this.destroying$.complete();
  }
}
