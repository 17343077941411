import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginInfo } from 'app/shared/interfaces/loginInfo.model';
import { LoggedInUser } from 'app/shared/interfaces/user.model';
import { UtilsService } from 'app/shared/services/utils/utils.service';

@Component({
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styleUrls: ['./unauthorized-access.component.scss']
})
export class UnauthorizedAccessComponent implements OnInit {
  /**
   *
   */
  loggedInUser : LoggedInUser;
  isUserPresent:boolean = true;
  constructor(private router: Router, private modalService: NgbModal, private utilService:UtilsService) {
  }

  ngOnInit(): void {
    this.modalService.dismissAll();
    this.loggedInUser = JSON.parse(localStorage.getItem('logged_in_user') || '{}');
    const loginInfo: LoginInfo = this.utilService.getToken() as LoginInfo;
    if(!this.loggedInUser || !this.loggedInUser.primaryEmail || !loginInfo || !loginInfo.accessToken || !loginInfo.idToken){
      this.isUserPresent = false;
    }
  }

  loginPageRedirect(){
    if(this.isUserPresent){
    this.router.navigateByUrl('home');
    }
    else{
    this.router.navigateByUrl('login');
    }
    // if(this.loggedInUser.roleId === 6){
    //   this.router.navigateByUrl('SharedPlans');
    // }else{
    //   this.router.navigateByUrl('home');
    // }
  }
}
