import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class NgbDateFormattingService extends NgbDateParserFormatter  {

  parse(value: string): NgbDateStruct | null 
  {
    if (!value)
      return null
     let parts=value.split('/');
     return {year:+parts[0],month:+parts[1],day:+parts[2]} as NgbDateStruct

  }
  format(date: NgbDateStruct): string 
  {
    if(date){
      var dateLocal = new Date(date.year , date.month -1  , date.day);
      var monthLocalString = dateLocal.toLocaleDateString('default', { month: 'short' });
      return ('0'+date.day).slice(-2)+' '+monthLocalString+' '+date.year;
    }
    return '';
    // return date?+('0'+date.day).slice(-2)+"/"+('0'+date.month).slice(-2)+"/"+date.year : '';
  }

}
