import { Injectable } from '@angular/core';
import { NgbDate, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class NgbDateCustomService extends NgbDateAdapter<any> {
	readonly DELIMITER = '/';

	fromModel(value: string | NgbDateStruct): NgbDateStruct | null {
		if (typeof value === 'string') {
			const date = value.split(this.DELIMITER);
			return {
				month: parseInt(date[0], 10),
				day: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		else if(value != null){
			return value;
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): NgbDateStruct | null {
    var lDate = "";
    if(date){
		return date;
        // lDate =  date.month + this.DELIMITER + date.day + this.DELIMITER +  date.year ;
    }
		// return date ? lDate : null;
		return null;
	}
}