import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { LoginInfo } from '../../interfaces/loginInfo.model';
import { Language } from 'app/shared/constants/language';
import { TranslateService } from '@ngx-translate/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CommonHttpService } from '../common-http/common-http.service';
import { NotificationModel } from 'app/shared/interfaces/user.model';
import { CustomDatePipe } from 'app/shared/pipes/custom-date.pipe';
import { DatePipe } from '@angular/common';
import { dateFormat } from 'app/shared/constants/dateFormat';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  notificationCount: any = new Subject();
  updateAttechmentCount: any = new Subject();
  pathChangeRequest: any = new Subject();
  leftSideBarCompact: any = new BehaviorSubject<boolean>(false);
  leftSubSideBarCompact: any = new BehaviorSubject<boolean>(false);
  assistanceToPlanDashBoardSearchText: any = new BehaviorSubject<string>('');
  notificationData: any = new BehaviorSubject<NotificationModel | null>(null);
  pendingActionLinking: any = new BehaviorSubject<boolean>(false);
  linkRedirectFromReview : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translateService: TranslateService,
    private commonHttpService: CommonHttpService,
    private customDate: CustomDatePipe
  ) {
    this.gantD = null;
  }

  /**
   * for passing date from gantt chart to op creation
   *
   */
  gantD: Date | null;

  set ganttDate(value: Date | null) {
    this.gantD = value;
  }

  get ganttDate() {
    return this.gantD;
  }

  /**
   * get token from localStorage
   * @param tokenType accessToken or idToken
   */

  /**
   * If logged in user details are changed
   */
  private refreshLocalStorageUser = new Subject<any>();
  userRefresh = this.refreshLocalStorageUser.asObservable();

  // variable to manage to show or not loader in api calls
  private _loaderState : boolean = true;

  getToken(tokenType?: string): LoginInfo | string {
    const loginInfo: LoginInfo = JSON.parse(
      localStorage.getItem('login_info') || '{}'
    );
    switch (tokenType) {
      case 'accessToken':
        return loginInfo.accessToken;
      case 'idToken':
        return loginInfo.idToken;
      default:
        return loginInfo;
    }
  }

  refreshLoggedInUserDetails(): void {
    // call this function whenever you want to refresh the list
    this.refreshLocalStorageUser.next('');
  }

  // Probably unnecessary
  // loadLanguages(): void {
  //   this.translateService.setDefaultLang('fr');
  //   this.translateService.setDefaultLang('pt');
  //   this.translateService.setDefaultLang('es');
  //   this.translateService.setDefaultLang('en');
  // }

  // multi-language set
  setLanguage(languageId: number): void {
    const languageCode = Language.find(
      (language) => language.languageId === languageId
    )?.languageCode;
    if (languageCode) {
      this.translateService.use(languageCode);
    }
  }

  dateToNgbDate(
    date: Date,
    year: number = 0,
    month: number = 0,
    day: number = 0
  ) {
    return new NgbDate(
      date.getFullYear() + year,
      date.getMonth() + 1 + month,
      date.getDate() + day
    );
  }

  addToNgbDate(
    ngbDate: NgbDate,
    year: number = 0,
    month: number = 0,
    day: number = 0
  ) {
    const momentDate = moment(`${ngbDate.year}-${ngbDate.month}-${ngbDate.day}`, 'YYYY-MM-DD');

    momentDate.add(year, 'year');
    momentDate.add(month, 'months');
    momentDate.add(day, 'days');
  
    const updatedNgbDate: NgbDate = new NgbDate(
    momentDate.year(),
    momentDate.month() + 1, // Month in Moment.js is 0-based, NgbDate is 1-based
    momentDate.date()
    );
  return updatedNgbDate;
  }

  addYears(date: Date, year: number) {    
    var momDate = new Date(date);
    momDate.setFullYear(date.getFullYear() + year);
    momDate.setDate(momDate.getDate() - 1);
    return momDate;
  }

  ngbDateToDate(
    date: NgbDate,
    year: number = 0,
    month: number = 0,
    day: number = 0
  ) {
    return new Date(date.year + year, date.month + month - 1, date.day + day);
  }

  getFormattedDate(date: Date): string {
    var dateVal: string = this.customDate.transform(date, dateFormat)!;
    return dateVal;
  }

  getImage(imageId: string) {
    return this.commonHttpService.getSecuredResource(
      `/User/GetUserImage/${imageId}`
    );
  }

  getDate(date : Date){
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  get loaderState(): boolean {
    return this._loaderState;
  }

  // Set the boolean value
  set loaderState(value: boolean) {
    this._loaderState = value;
  }
}
