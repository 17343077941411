import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/shared/services/auth-guard/auth.guard';
import { Roles } from 'app/shared/constants/role.constant';
import { RoleGuard } from 'app/shared/services/auth-guard/role.guard';
import { UnauthorizedAccessComponent } from 'app/shared/components/unauthorized-access/unauthorized-access.component';
import { sideBarUser } from 'app/shared/constants/sideBarUserAccess';

const routes: Routes = [
  {
    path:'',
    redirectTo: 'login',
    pathMatch : 'full'
  },
  {
    path: 'login',
    loadChildren:() => import('../../app/authentication/authentication.module').then(m => m.AuthenticationModule),
    data: { topNavigation: false, sideNavigation: false, selectedModule: 'login', title: 'NAPHS'  }
  },
  {
    //this is to set the default route to home component for initial state only
    path: 'home',
    loadChildren: () => import('../admin/home/home.module').then(m => m.HomeModule),
    data: { topNavigation: true, sideNavigation: true, selectedModule: 'home', title: 'NAPHS', rightSideNavigation: true , 
    role : [...sideBarUser['Home']] },
    canActivate: [AuthGuard]
  },
  // {
  //   path:'home',
  //   loadChildren:() => import('../admin/home/home.module').then(m => m.HomeModule),
  //   data: { topNavigation: true, sideNavigation: true, selectedModule: 'home', title: 'NAPHS' }
  // },
  {
    path: 'plan',
    loadChildren: () => import('../admin/plan/plan.module').then(m => m.PlanModule),
    data: { topNavigation: true, sideNavigation: true, selectedModule: 'plan', title: 'Plan' , rightSideNavigation: true ,
    role : [...sideBarUser['Plan']]
  },
    canActivate: [AuthGuard , RoleGuard]
  },
  {
    path: 'SharedPlans',
    loadChildren: () => import('../admin/shared-plans/shared-plans.module').then(m => m.SharedPlansModule),
    data: { topNavigation: true, sideNavigation: true, selectedModule: 'sharedPlan', title: 'Shared Plan' , rightSideNavigation: true ,
      role : [...sideBarUser['Shared Plans']]
    },
    canActivate: [AuthGuard , RoleGuard]
  },
  {
    path: 'userManagement',
    loadChildren: () => import('../admin/user-management/user-management.module').then(m => m.UserManagementModule),
    data: { topNavigation: true, sideNavigation: true, selectedModule: 'userManagement', title: 'User Management' ,
    role: [...sideBarUser['User Management']]
  },
    canActivate: [AuthGuard , RoleGuard]
  },
  {
    path: 'systemSettings',
    loadChildren: () => import('../admin/system-settings/system-settings.module').then(m => m.SystemSettingsModule),
    data: { topNavigation: true, sideNavigation: true, selectedModule: 'systemSettings', title: 'System Settings' ,
    role: [...sideBarUser['System Settings']]
  },
    canActivate: [AuthGuard,RoleGuard]
  },
  {
    path: 'unauthorizedAccess',
    component : UnauthorizedAccessComponent,
    data : { topNavigation: false, sideNavigation: false, title: 'Unauthorized Access'  }
  },
  {
    path: 'assistance',
    loadChildren : () => import('../admin/assistance/assistance.module').then(m => m.AssistanceModule),
    data: { topNavigation: true, sideNavigation: true, selectedModule: 'assistance', title: 'Assistance Module', 
    role: [...sideBarUser['Technical Assistance']]
  },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'review',
    loadChildren: () => import('../admin/review/review.module').then(m => m.ReviewModule),
    data: { topNavigation: true, sideNavigation: true, selectedModule: 'reviewModule', title: 'Review' ,
    role: [...sideBarUser['Review']]
  },
    canActivate: [AuthGuard,RoleGuard]
  },
  {
    path: 'actions',
    loadChildren : () => import('../admin/import-actions/import-actions.module').then(m => m.ImportActionsModule),
    data: { topNavigation: true, sideNavigation: true, selectedModule: 'importActions', title: 'Import Actions', 
    role: [...sideBarUser['Financial Assistance']]
  },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
