/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  
  function toggleLeftSidebar() {
    var element = document.getElementById("left-sidebar");
    if (element) element.classList.toggle("compact");
    var element = document.getElementById("main-container");
    if (element) element.classList.toggle("compact-left");
    var element = document.getElementById("left-sub-sidebar");
    if (element) element.classList.toggle("compact-sub-sidebar-left");
  }