<nav class="navbar navbar-expand-lg top-navbar">
    <div class="container-fluid position-relative flex-nowrap">
        <a class="navbar-brand cursor-pointer" (click)="redirectToDefaultPage()">
            <img src="../../../assets/images/div.d-flex.png" />
            <img class="d-none d-lg-inline-block" src="../../../assets/images/NAPHS _ National Action Plan for Health Security.png" />
        </a>

        <div class="header-span" *ngIf="isUAT">
            User Acceptance Test Environment
        </div>
        <ul class="navbar-nav d-flex align-items-center">
            <!-- <li class="nav-item ">
                <select class="language-selection form-select font-size-14">
                    <option>{{'English' | translate}}</option>
                    <option>{{'French' | translate}}</option>
                    <option>{{'Portuguese' | translate}}</option>
                    <option>{{'Spanish' | translate}}</option>
                </select>
            </li> -->
            <li *ngIf="isLoggedIn && loggedInUser.roleId != 6" class="cst-notification nav-item dropdown notification">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    <i class="bi bi-bell"></i>
                    <span *ngIf="getUnreadCount() != 0" class="badge rounded-pill badge-notification bg-danger">{{ getUnreadCount() }}</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg-end notifications--list" aria-labelledby="dropdownMenuClickableInside">
                    <h5 class=" nt-header">{{'Notifications' | translate}}
                        <a href="javascript:;" (click)="markNotificationAsRead(-1)" aria-expanded="false" *ngIf="getUnreadCount() != 0"><small>{{'markAllAsRead' | translate}}</small></a>
                    </h5> 
                   
                    <ul aria-labelledby="navbarDropdownMenuLink" class="nt-body">
                        <p class="no-notification" *ngIf="notifications.length == 0">{{'inboxIsEmpty' | translate}}!</p>
                        <!-- <li class="dropdown-item" *ngIf="notifications.length == 0">Inbox is empty!</li> -->
                        <li class="dropdown-item cursor-pointer list-group-item" *ngFor="let notification of notifications; let i = index" (click)="markNotificationAsRead(i)" [ngClass]="{'bg-grey' : !notification.is_read}">
                            <span class="user-avtaar">
                                <em class="bi bi-envelope"></em>
                            </span>
                            <ng-container *ngIf="notification.n_type == 1">
                                <h6 class="text-truncate">
                                    <span *ngIf="notification.sa_id != null">{{'strategicAction' | translate}}</span>
                                    <span *ngIf="notification.da_id != null">{{'operationalAction' | translate}}</span>
                                    has been edited in plan {{notification.planCode}}.
                                    <small class="d-block time-stamp">{{toLocalDate(notification.unixTimeMilliseconds) | customDate: 'customDt'}}</small>
                                </h6>
                                <!-- <a href="javascript:;" class="close-notification-icon"
                                (click)="false"><em
                                  class="bi bi-bookmark-check font-size-20"></em></a> -->
                            </ng-container>
                            <ng-container *ngIf="notification.n_type == 2">
                                <h6 class="text-truncate">Please complete the review session of {{notification.planCode}}. The due date is {{toLocalDate(notification.reviewDateSeconds) | customDate: 'customDt'}} and the review status is {{notification.reviewStatus}}.
                                    <small class="d-block time-stamp">{{toLocalDate(notification.unixTimeMilliseconds) | customDate: 'customDt'}}</small>
                                </h6>
                            </ng-container>
                            <ng-container *ngIf="notification.n_type == 3">
                                <h6 class="text-truncate">
                                    <span>View pending invitation.</span><br>
                                    <span>Creator : {{notification.createdByUser}} {{notification.createdByEmail}}.</span>
                                    <small class="d-block time-stamp">{{toLocalDate(notification.unixTimeMilliseconds) | customDate: 'customDt'}}</small>
                                </h6>
                                <!-- <a href="javascript:;" class="close-notification-icon"
                                (click)="false"><em
                                  class="bi bi-bookmark-check font-size-20"></em></a> -->
                            </ng-container>
                            <ng-container *ngIf="notification.n_type == 4 || notification.n_type == 5">
                                <h6 class="text-truncate">
                                    <span>Your changes of </span> 
                                    <span *ngIf="notification.sa_id != null">Strategic action</span>
                                    <span *ngIf="notification.da_id != null">Operational action</span>
                                     has been {{notification.n_type == 4 ? "Approved" : "Rejected"}}
                                    by {{notification.createdByUser}}.
                                    <small class="d-block time-stamp">{{toLocalDate(notification.unixTimeMilliseconds) | customDate: 'customDt'}}</small>
                                </h6>
                            </ng-container>
                            <ng-container *ngIf="notification.n_type == 6">
                                <h6 class="text-truncate">Reminder to complete previous review session of {{notification.planCode}}, as the next review session will commence on {{toLocalDate(notification.reviewDateSeconds) | customDate: 'customDt'}}.
                                    <small class="d-block time-stamp">{{toLocalDate(notification.unixTimeMilliseconds) | customDate: 'customDt'}}</small>
                                </h6>
                            </ng-container>
                            <ng-container *ngIf="notification.n_type == 8">
                                <h6 class="text-truncate">New Strategic Actions Added in {{notification.planCode}}.
                                    <small class="d-block time-stamp">{{toLocalDate(notification.unixTimeMilliseconds) | customDate: 'customDt'}}</small>
                                </h6>
                            </ng-container>
                            <ng-container *ngIf="notification.n_type == 9">
                                <h6 class="text-truncate">Review for {{notification.planCode}} is pending for approval.
                                    <small class="d-block time-stamp">{{toLocalDate(notification.unixTimeMilliseconds) | customDate: 'customDt'}}</small>
                                </h6>
                            </ng-container>
                        </li>
                        <li class="justify-content-center"  *ngIf="notifications.length !== 0 && !hasAllNotifications">
                        <a href="javascript:;" class="view-all-notifications" (click)="getNotifications(true)">
                          {{'viewAll' | translate}}</a>
                      </li>
                    </ul>
                    <!-- <div class="d-flex justify-content-between nt-footer">
                        
                        <a href="javascript:void(0)" (click)="markNotificationAsRead(-1)">Mark all as read</a>
                    </div> -->
                </div>
            </li>
            <li *ngIf="isLoggedIn" class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <div class="user-avatar-wrapper mx-1 my-0">
                        <div class="user-profile-photo">
                            <img class="profile-image img-fluid"
                            [src]="profileImage && profileImage !== '' ? profileImage : '../../../assets/images/profile-placeholder.jpg'">
                        </div>
                    </div>
                    <div>
                        {{loggedInUser.firstName + " " + loggedInUser.lastName}}
                    </div>
                </a>
                <ul class="dropdown-menu dropdown-menu-end user-dropdown-menu">
                    <li><a class="dropdown-item cursor-pointer" (click)="redirectToEditProfile()"><i class="bi bi-person-fill me-2"></i>{{'Profile' | translate}}</a></li>
                    <!-- <li><a class="dropdown-item cursor-pointer" href="#"><i class="bi bi-gear-fill me-2"></i>Settings</a></li> -->
                    <!-- <li><a class="dropdown-item cursor-pointer" (click)="downloadUserGuide()"><i
                                class="bi bi-cloud-arrow-down-fill me-2"></i>User guide</a>
                    </li> -->
                    <li><a class="dropdown-item cursor-pointer" (click)="logoutUser()"><i
                                class="bi bi-box-arrow-right me-2"></i>Logout</a>
                    </li>
                </ul>
            </li>
            <!-- <li *ngIf="!isLoggedIn" class="nav-item px-2">
                <button class="button btn btn-default" (click)="loginUser()">LogIn</button>
            </li> -->
        </ul>
    </div>
</nav>