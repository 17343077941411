import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor() {}
  private cachedResources: Map<string, SafeUrl> = new Map();

  // Store a cached resource path
  store(key: string, path: SafeUrl): void {
    this.cachedResources.set(key, path);    
  }

  // Retrieve a cached resource path
  retrieve(key: string): SafeUrl | undefined {
    return this.cachedResources.get(key);
  }
}
