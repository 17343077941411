import { Injectable } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { LoggedInUser } from '../../interfaces/user.model';
import { Subject, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  profilePictureChangedSubject = new Subject<string>();
  profileDetailsChangedSubject = new Subject<any>();

  async getLoggedInUserDetails() : Promise<LoggedInUser> {    
    return await lastValueFrom(this.commonHttpService.get<LoggedInUser>({
      uri: `/User/GetClaimsUserDetails`,
      showToastr: true,
      message:{
      }
    }));
  }
  saveUserLoginActivity(arg0: string) {
    throw new Error('Method not implemented.');
  }

  constructor(private commonHttpService : CommonHttpService) {}

}
