import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Menu {
  headTitle?: string,
  isItem?: boolean,
  itemClass?: string,
  headTitle2?: string,
  path?: string;
  subDefaultPath?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeValue?: string;
  badgeClass?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  Menusub?: boolean;
  target?: boolean;
  permission: boolean;
  translatedTitle?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() { }

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  MENUITEMS: Menu[] = [
    {
      title: 'Home',
      translatedTitle: 'home',
      icon: 'house-door-fill',
      type: 'link',
      path: '/home',
      Menusub: false,
      active: false,
      isItem: true,
      permission : false
    },
    {
      title: 'Plan',
      translatedTitle: 'plan',
      icon: 'grid-fill',
      type: 'link',
      path: '/plan',
      Menusub: false,
      active: false,
      isItem: true,
      permission : false,
      children: [
        {
          title: 'Country Plans',
          icon: 'grid',
          type: 'link',
          path: '/plan/CountryPlans',
          Menusub: false,
          active: false, isItem: true,
          permission : false
        },
        {
          title: 'Strategic Plan',
          icon: 'grid',
          type: 'link',
          path: '/plan/StrategicPlan',
          Menusub: false,
          active: false, 
          isItem: true,
          permission : false,
        },
        {
          title: 'Operational Plan',
          icon: 'grid',
          type: 'link',
          path: '/plan/OperationalPlan',
          Menusub: false,
          active: false, isItem: true,
          permission : false,
        },
        {
          title: 'Shared Plans',
          icon: 'grid',
          type: 'link',
          path: '/plan/SharedPlans',
          Menusub: false,
          active: false, isItem: true,
          permission : false,
        },
      ]
    },
    {
      title: 'Import Actions',
      translatedTitle: 'importAdditionalAssessment',
      icon: 'dice-1-fill',
      type: 'link',
      path: '/actions',
      Menusub: false,
      active: false,
      isItem: true,
      permission : false
    },
    // {
    //   title: 'Tools',
    //   icon: 'gear-fill',
    //   type: 'link',
    //   path: '/tools',
    //   Menusub: true,
    //   active: false, isItem: true,
    //   children: [
    //     {
    //       title: 'Download Excel Template',
    //       icon: 'grid',
    //       type: 'link',
    //       path: '/homex',
    //       Menusub: false,
    //       active: false, isItem: true,
    //     },
    //     {
    //       title: 'Technical Assistance',
    //       icon: 'grid',
    //       type: 'link',
    //       path: '/homex',
    //       Menusub: false,
    //       active: false, isItem: true,
    //     },
    //     {
    //       title: 'Financial Assistance',
    //       icon: 'grid',
    //       type: 'link',
    //       path: '/homex',
    //       Menusub: false,
    //       active: false, isItem: true,
    //     },
    //     {
    //       title: 'Reference Library',
    //       icon: 'grid',
    //       type: 'link',
    //       path: '/homex',
    //       Menusub: false,
    //       active: false, isItem: true,
    //     },

    //   ]
    // },
    {
      title: 'Shared Plans',
      translatedTitle: 'sharedPlan',
      icon: 'ubuntu',
      type: 'link',
      path: '/SharedPlans',
      Menusub: false,
      active: false,
      isItem: true,
      permission : false
    },
    {
      title: 'User Management',
      translatedTitle: 'countryTeam',
      icon: 'person-fill',
      type: 'link',
      path: '/userManagement',
      Menusub: false,
      active: false, isItem: true,
      permission : false,
    },
    {
      isItem: false,
      active: false,
      badgeClass: 'line-breaker',
      itemClass: 'side-menu-divider',
      icon: '',
      path: '',
      title: 'line-breaker1',
      permission : false,
    },
    {
      title: 'Technical Assistance',
      translatedTitle : 'technicalAssistance',
      icon: 'laptop',
      type: 'link',
      path: '/assistance/technical',
      Menusub: true,
      active: false, isItem: true,
      permission : true,
    },
    {
      title: 'Financial Assistance',
      translatedTitle : 'financialAssistance',
      icon: 'bank',
      type: 'link',
      path: '/assistance/financial',
      Menusub: true,
      active: false,
      isItem: true,
      permission : true,
    },
    {
      isItem: false,
      active: false,
      badgeClass: 'line-breaker',
      itemClass: 'side-menu-divider',
      icon: '',
      path: '',
      title: 'line-breaker2',
      translatedTitle: '',
      permission : false,
    },
    {
      title: 'System Settings',
      translatedTitle: 'systemSettings',
      icon: 'gear-wide-connected',
      type: 'link',
      path: '/systemSettings',
      subDefaultPath: '/systemSettings/planning-tool',
      Menusub: true,
      active: false, isItem: true,
      permission : false,
      children: [
        {
          title: 'Strategic Actions',
          icon: 'grid',
          type: 'link',
          path: '/homex',
          Menusub: false,
          active: false, isItem: true,
          permission : false,
        },
        {
          title: 'Planning Tools',
          icon: 'grid',
          type: 'link',
          path: 'systemSettings/planning-tool',
          Menusub: false,
          active: false, isItem: true,
          permission : false,
        },
        {
          title: 'Languages',
          icon: 'grid',
          type: 'link',
          path: '/homex',
          Menusub: false,
          active: false, isItem: true,
          permission : false,
        },
        {
          title: 'System Notifications',
          icon: 'grid',
          type: 'link',
          path: '/homex',
          Menusub: false,
          active: false, isItem: true,      
          permission : false,
        },
      ]
    },
    {
      title: 'Review',
      translatedTitle: 'review',
      icon: 'file-earmark-medical-fill',
      type: 'link',
      path: '/review',
      Menusub: false,
      active: false,
      isItem: true,
      permission : false
    },
    {
      isItem: false,
      active: false,
      badgeClass: 'line-breaker',
      itemClass: 'side-menu-divider',
      icon: '',
      path: '',
      title: 'line-breaker3',
      translatedTitle: '',
      permission : false,
    },
    {
      title: 'Contact',
      translatedTitle: 'contact',
      icon: 'question-circle-fill',
      type: 'email',
      path: '/home',
      Menusub: false,
      active: false, isItem: true,
      permission : false,
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
