import { PrecedenceRole, Role } from "../interfaces/role.model";


export const Roles = {
    SystemAdmin: {
        roleId: 1,
        role: 'SystemAdmin',
        description: 'System Admin'
    },
    RegionalAdmin: {
        roleId: 2,
        role: 'RegionalAdmin',
        description: 'Regional Admin'
    },
    CountryAdmin: {
        roleId: 3,
        role: 'CountryAdmin',
        description: 'Country Admin'
    },
    CountryUser: {
        roleId: 4,
        role: 'CountryUser',
        description: 'Country User'
    },
    Secretariat: {
        roleId: 5,
        role: 'Secretariat',
        description: 'Secretariat'
    },
    GlobalViewer: {
        roleId: 6,
        role: 'GlobalViewer',
        description: 'Global Viewer'
    },
    CountryViewer: {
        roleId: 7,
        role: 'CountryViewer',
        description: 'Country Viewer'
    }
};

export const RoleList: Role[] = [
    {
        roleId: 1,
        role: 'SystemAdmin',
        description: 'System Admin'
    },
    {
        roleId: 2,
        role: 'RegionalAdmin',
        description: 'Regional Admin'
    },
    {
        roleId: 3,
        role: 'CountryAdmin',
        description: 'Country Admin'
    },
    {
        roleId: 4,
        role: 'CountryUser',
        description: 'Country User'
    },
    {
        roleId: 5,
        role: 'Secretariat',
        description: 'Secretariat'
    },
    {
        roleId: 6,
        role: 'GlobalViewer',
        description: 'Global Viewer'
    },
    {
        roleId: 7,
        role:'CountryViewer',
        description:'Country Viewer'
    }
];

export const PrecedenceRoleList: PrecedenceRole[] = [
    {
        roleId: 1,
        role: 'SystemAdmin',
        description: 'System Admin',
        precedence:1
    },
    {
        roleId: 2,
        role: 'RegionalAdmin',
        description: 'Regional Admin',
        precedence:2
    },
    {
        roleId: 3,
        role: 'CountryAdmin',
        description: 'Country Admin',
        precedence:3
    },
    {
        roleId: 5,
        role: 'Secretariat',
        description: 'Secretariat',
        precedence:4
    },
    {
        roleId: 4,
        role: 'CountryUser',
        description: 'Country User',
        precedence:5
    },
    {
        roleId: 6,
        role: 'GlobalViewer',
        description: 'Global Viewer',
        precedence:6
    },
    {
        roleId: 7,
        role:'CountryViewer',
        description:'Country Viewer',
        precedence:7
    }
];
