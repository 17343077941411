import { Roles } from './role.constant';

export const sideBarUser: { [key: string]: string[] } = {
  Home: [],
  'Import Actions': [
    Roles.SystemAdmin.role,
    Roles.RegionalAdmin.role,
    Roles.CountryAdmin.role,
  ],
  'Plan': [
    Roles.SystemAdmin.role,
    Roles.RegionalAdmin.role,
    Roles.CountryAdmin.role,
    Roles.Secretariat.role,
    Roles.CountryUser.role,
    Roles.CountryViewer.role,
  ],
  'Shared Plans': [
    Roles.SystemAdmin.role,
    Roles.RegionalAdmin.role,
    Roles.CountryAdmin.role,
    Roles.Secretariat.role,
    Roles.CountryUser.role,
    Roles.CountryViewer.role,
    Roles.GlobalViewer.role,
  ],
  'User Management': [
    Roles.SystemAdmin.role,
    Roles.CountryAdmin.role,
    Roles.RegionalAdmin.role,
  ],
  'line-breaker1': [
    Roles.SystemAdmin.role,
    Roles.RegionalAdmin.role,
    Roles.CountryAdmin.role,
  ],
  'Technical Assistance': [
    Roles.SystemAdmin.role,
    Roles.RegionalAdmin.role,
    Roles.CountryAdmin.role,
  ],
  'Financial Assistance': [
    Roles.SystemAdmin.role,
    Roles.RegionalAdmin.role,
    Roles.CountryAdmin.role,
  ],
  'line-breaker2': [
    Roles.SystemAdmin.role,
    Roles.CountryAdmin.role,
    Roles.RegionalAdmin.role,
  ],
  'System Settings': [
    Roles.SystemAdmin.role
  ],
  Review: [
    Roles.SystemAdmin.role,
    Roles.CountryAdmin.role,
    Roles.RegionalAdmin.role,
    Roles.CountryUser.role,
    Roles.Secretariat.role
  ],
  'line-breaker3': [],
  Contact: [],
};
