import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: 'dashboard',
  //   loadChildren:() => import('../app/authentication/authentication.module').then(m => m.AuthenticationModule),
  //   data: { topNavigation: true, sideNavigation: false, selectedModule: 'login', title: 'NAPHS'  }
  // },
  {
    path:'',
    loadChildren:() => import('../app/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path:'**',
    redirectTo: 'plan',
    pathMatch:'full'
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  
}
