<div class="">
    <!-- <app-home-content></app-home-content> -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-12 p-0">
                <div class="login-left-side">
                    <h1>e-NAPHS</h1>
                    <div class="login-aboutus">
                        <h5>About NAPHS</h5>
                        <p>National Action Planning for Health Security (NAPHS) is a country owned, multi-year, planning
                            process that
                            is based on One Health for all-hazards, and whole-of-government approach. It captures
                            national priorities
                            for health security including key actions for addressing capacity gaps, and resources
                            required to accelerate
                            development of IHR core capacities. Since 2016, WHO has worked closely with Member States
                            and partners to
                            support development and implementation of NAPHS through technical instruments and advocacy
                            for increased
                            funding for development of IHR core capacities to improve health security.</p>
                    </div>
                    <div class="login-quicklinks">
                        <h5>Quick Links</h5>
                        <div class="d-flex">
                            <div class="white-img-box-wrapper">
                                <div class="white-img-box who"></div>
                                <p class="title cursor-pointer"><a href="https://www.who.int/" style="color: white;"
                                        target="_blank">WHO Home</a></p>
                            </div>
                            <div class="white-img-box-wrapper">
                                <div class="white-img-box sph"></div>
                                <p class="title cursor-pointer"><a href="https://extranet.who.int/sph/naphs"
                                        style="color: white;" target="_blank">SPH</a></p>
                            </div>
                            <div class="white-img-box-wrapper">
                                <div class="white-img-box who"></div>
                                <p class="title cursor-pointer"><a href="https://openwho.org/courses/NAPHS-intro"
                                        style="color: white;" target="_blank">Training</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 p-0">
                <div class="login-right-side">
                    <img src="../../../../assets/images/E_NAPHS_logo (no background).png" />
                    <h1>National Action Plan for Health Security</h1>
                    <button type="button" class="btn btn-default" (click)="loginUser()"> Login </button>
                </div>
            </div>
        </div>
    </div>
</div>