import { Component, HostListener, OnInit } from '@angular/core';
import { RouteData } from './shared/interfaces/routingParameters';
import { ResolveStart, Router } from '@angular/router';
import { Subject, filter, map } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService  } from '@azure/msal-angular';
import { UserService } from './shared/services/user/user.service';
import { UtilsService } from './shared/services/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'NAPHS';
  routeData : RouteData = {
    sideNavigation : false,
    topNavigation: false,
    rightSideNavigation: false,
    allowedRoles : [],
    title : '',
    selectedModule : ''
  };
  isSidebarCompact: boolean = false;

  selectedModule: string;
  userActivity: NodeJS.Timeout;
  userInactive: Subject<any> = new Subject();
  isLoggedIn : boolean = true;

  constructor(
    private router: Router,
    private titleService: Title,
    private translateService: TranslateService,
    private msalBroadcastService : MsalBroadcastService ,
    private msalService: MsalService,
    private userService: UserService,
    private utilsService: UtilsService
  ) {
    this.translateService.addLangs(['en', 'fr', 'pt', 'es']);
    this.translateService.setDefaultLang('en');
    // this.utilsService.loadLanguages();
    const loggedInUser = JSON.parse(localStorage.getItem('logged_in_user') || '{}');
    const loggedInUserCheck = JSON.parse(localStorage.getItem('login_info') || '{}');
    if(Object.keys(loggedInUserCheck).length === 0)
    {
      this.isLoggedIn = false;
    }
    if (loggedInUser.languageId) {
      // this.utilsService.setLanguage(loggedInUser.languageId);
    } else {
      // Default english can be set by setting the number to 1
      this.utilsService.setLanguage(3);
    }
    this.subscribeNavigation();
    this.setTimeout();
    this.autoLogout();
  }
  ngOnInit(): void {
    // this.setSubSidebarState();
    this.utilsService.leftSideBarCompact.subscribe(
      (val : boolean) => {
        this.isSidebarCompact = val;
      }
    );
  }


  subscribeNavigation() {
    this.router.events.pipe(
      filter((event: any) => event instanceof ResolveStart),
      map((event: any) => {
        let data = null;
        let route = event['state'].root;
        while (route) {
          data = route.data || data;
          route = route.firstChild;
        }
        return data;
      }),
    ).subscribe((result: any) => {
      this.routeData = result;
      if (this.routeData) {
        this.title = this.routeData.title;
        this.titleService.setTitle(this.title);
        this.selectedModule = this.routeData.selectedModule;
      }
    })
  }

   /**
   * auto logout function
   */
   async autoLogout(): Promise<void> {
    this.userInactive.subscribe(async () => {
      // await this.userService.saveUserLoginActivity('logout');
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/'
      });
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }


  /**
   * set timeout for auto logout
   */
  setTimeout(): void {
    this.userActivity = setTimeout(() => this.userInactive.next(null), 3600000);
  }

  /**
   * on mouse move event clear previous timeout and add new timeout for logout
   */
  @HostListener('window:mousemove') refreshUserState(): void {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  // setSubSidebarState() {
  //   var sidebar = document.getElementById("left-sidebar");
  //   if (sidebar?.classList?.contains('compact')) this.isSidebarCompact = true;
  // }


}
