import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from '../../interfaces/loader.model';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  showLoader(): void {
    this.loaderSubject.next({ show: true } as LoaderState);
  }
  hideLoader(): void{
    this.loaderSubject.next({ show: false } as LoaderState);
  }
}
