export const environment = {
  production: false,
  instance : 'DEV',
  isExpertEditable: false,
  authOptions: {
    clientId: '9038bfe4-467a-460d-90b6-ed54898233cd',
    tenantId: 'f610c0b7-bd24-4b39-810b-3dc280afb590',
    redirectUri: window.location.origin + '/dashboard',
    scopes: ['User.Read', 'openid', 'profile']
  },
  serverUrl: 'https://enaphs-dev.who.int/api',
  maxRecommendationsCount: 5
};
