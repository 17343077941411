import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { HttpConfigInterceptor } from './authentication/interceptor/http-config.interceptor';
import { MsalGuard } from '@azure/msal-angular';
import { AuthenticationModule } from './authentication/authentication.module';
import { AdminModule } from './admin/admin.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './shared/services/http-loader-factory';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LayoutComponentsModule } from './layout-components/layout-components.module';
import {
  NgbActiveModal,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFormattingService } from './shared/services/ngb-date-formatting/ngb-date-formatting.service';
import { NgbDateCustomService } from './shared/services/ngb-date-formatting/ngb-date-custom.service';

@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutComponentsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({}),
    AuthenticationModule,
    AdminModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { 
      provide: NgbDateAdapter, 
      useClass: NgbDateCustomService 
    },
    { 
      provide: NgbDateParserFormatter, 
      useClass: NgbDateFormattingService 
    },
    MsalGuard,
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
