import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { toasterConfig } from '../../constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

/**
 * Message Service created for showings toaster using ngx-toastr package
 */

export class MessageService {

  toasterConfig = toasterConfig;
  constructor(private toastr: ToastrService,
    private translateService : TranslateService
    ) { }


  // available types 'success', 'error', 'info', 'warning'
  // this four fucntions should be converted to one but currently i don't know how to do it.

  /**
   * @description below all four functions used to show various toaster messages
   * @param message // used to show message
   * @param title // used to title message
   *
   */

  showSuccessMessage(message: string, title?: string): void {
    var transSave = this.isAvailable(message);
   this.toastr.success(transSave ? this.translateService.instant(message) : message ,title ? this.translateService.instant(title) : "", this.toasterConfig);
  }

  showErrorMessage(message: string, title?: string): void {
    var transSave = this.isAvailable(message);
   this.toastr.error(transSave ? this.translateService.instant(message) : message ,title ? this.translateService.instant(title) : "", this.toasterConfig);
  }

  showInfoMessage(message: string, title?: string): void {
    var transSave = this.isAvailable(message);
   this.toastr.info(transSave ? this.translateService.instant(message) : message ,title ? this.translateService.instant(title) : "", this.toasterConfig);
  }

  showWarningMessage(message: string, title?: string): void {
    var transSave = this.isAvailable(message);
   this.toastr.warning(transSave ? this.translateService.instant(message) : message ,title ? this.translateService.instant(title) : "", this.toasterConfig);
  }

  isAvailable(message : string): boolean {
    const translation = this.translateService.instant(message);
    return translation !== message;
  }

}
