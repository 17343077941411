import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const loggedInUser = JSON.parse(localStorage.getItem('logged_in_user') || '{}');
    if (route.data['role'].includes(loggedInUser.role.role) || route.data['role'].length === 0) {
      return true;
    } else {
      this.router.navigate(['/unauthorizedAccess']);
      return false;
    }
  }
}
