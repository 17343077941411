import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { dateFormat, dateTimeFormat } from '../constants/dateFormat';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: any, format : string ): string | null {
    if (!value) return null;

    const datePipe = new DatePipe('en-US');
    value = new Date(value); 

    switch (format) {
      case 'custom' : 
        return datePipe.transform(value, dateFormat);
      case 'customDt' : 
        return datePipe.transform(value, dateTimeFormat);
      default:
        return datePipe.transform(value, format);
    }
  }

}
