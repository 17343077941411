import { environment } from '../../environments/environment';
import { InjectionToken } from '@angular/core';

export const serverUrl = environment.serverUrl;

// export const identityUrl = environment.identityUrl;

export const RESUMABLE_TOKEN = new InjectionToken('Resumable');

export const toasterConfig = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  onclick: null,
  showDuration: 300,
  hideDuration: 3000,
  timeOut: 3000,
  extendedTimeOut: 1000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  toastClass: 'toaster-style ngx-toastr',
  enableHtml: true
};

export const notificationReloadInterval = 20000