import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginTrigger = new BehaviorSubject<boolean>(false);
  loginClick = new BehaviorSubject<boolean>(false);
  constructor() { 
  }
}
