import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import {
  from as observableFrom,
  Observable,
  switchMap,
  map,
  catchError,
  throwError,
  lastValueFrom,
} from 'rxjs';
import { LoaderService } from 'app/shared/services/loader/loader.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { LoginInfo } from 'app/shared/interfaces/loginInfo.model';
import { environment } from 'environments/environment';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { AuthenticationResult, SilentRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private utilsService: UtilsService,
    private authService: MsalService,
    private router: Router
  ) // private toast: ToastService,
  // private userService: UserService
  {}
  requestUrl: string ;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requestUrl = request.url;
    // show loader
    return observableFrom(this._getToken()).pipe(
      switchMap((loginInfo: LoginInfo) => {
        if (
          !(
            request.url.includes('Notification') ||
            request.url.includes('AcquireLock') ||
            request.url.includes('CheckCategoryStatus')
          )
        ) {
          if(this.utilsService.loaderState){
            this.loaderService.showLoader();
          }
        }
        if (loginInfo && loginInfo.idToken) {
          // for graph API use access token
          if (request.url.includes('https://graph.microsoft.com')) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${loginInfo.accessToken}`,
                'Content-Type': 'image/jpeg', // SET THE CONTENT TYPES AS IMAGE/JPEG
              },
            });
          } else {
            // we need access token to send email to the user, to do that we need access token for particular this Endpoint
            if ((request.method === 'POST' && request.url.endsWith('/User')) || (request.method == 'PUT' && request.url.endsWith('ApproveOrRejectProfile'))) {
              request = request.clone({
                setHeaders: {
                  'GraphToken': `${loginInfo.accessToken}`,
                },
              });
            }
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${loginInfo.idToken}`,
              },
            });
          }
        }
        return next.handle(request).pipe(
          map((response) => {
            if (response instanceof HttpResponse && !(
              request.url.includes('Notification') ||
              request.url.includes('AcquireLock') ||
              request.url.includes('CheckCategoryStatus')
            )) {
              if(this.utilsService.loaderState){
                this.loaderService.hideLoader();
              }
            }
            return response;
          }),
          catchError((error) => {
            if(this.utilsService.loaderState){
              this.loaderService.hideLoader();
            }
            if (error && error.status === 401 && !request.url.includes('Notification')) {
              // localStorage.clear();
              this.router.navigate(['/unauthorizedAccess']);
              return throwError(() => error);
            }
            return throwError(() => error);
          })
        ) as any as Observable<HttpEvent<any>>;
      })
    );
  }
  async _getToken(): Promise<LoginInfo> {
    var blank : LoginInfo = { accessToken : '', idToken : '' };
    const expireOn: string =
      localStorage.getItem('expireOn') || new Date().toString();
    var currDate : Date = new Date();
    currDate.setMinutes(currDate.getMinutes() + 60);
    if (currDate >= new Date(expireOn)) {
      await this.refreshToken();
    }
    return this.utilsService.getToken() as LoginInfo;
  }

  async refreshToken(): Promise<void> {
    try {
      const accessTokenRequest: SilentRequest = {
        scopes: [...environment.authOptions.scopes],
        account: this.authService.instance.getAllAccounts()[0],
        forceRefresh : true
      };
      if(!accessTokenRequest.account)
      {
        if(
          this.requestUrl.includes('i18n') ||
          this.requestUrl.includes('GetDashboardDetails')
        ){
          return ;
        }
      }
      //toPromise deprecated
      const accessTokenResponse: AuthenticationResult = await lastValueFrom(
        this.authService.acquireTokenSilent(accessTokenRequest)
      );
      this.authService.instance.setActiveAccount(accessTokenResponse.account);
      const loginInfo: LoginInfo = {
        accessToken: accessTokenResponse.accessToken,
        idToken: accessTokenResponse.idToken,
      };
      localStorage.setItem('login_info', JSON.stringify(loginInfo));
      localStorage.setItem(
        'expireOn',
        accessTokenResponse.expiresOn
          ? accessTokenResponse.expiresOn.toString()
          : new Date().toString()
      );
      // await this.userService.saveUserLoginActivity('login');
    } catch (error) {
      console.error(error);
    }
  }
}
