import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, OperatorFunction, of } from 'rxjs';
import { serverUrl } from '../../constants';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { Responsebody, RequestObj } from '../../interfaces/responseBody';
import { MessageService } from '../message/message.service';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { CacheService } from '../cache/cache.service';
// import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {

  profileId!: string;
  preRequestUrl: string = serverUrl;
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    // private sessionStorageService: SessionStorageService,
    private cacheService : CacheService
  ) {
   }

   gets<T>(requestObj: RequestObj<T>): Observable<T> {

    return this.http.get(this.preRequestUrl + requestObj.uri).pipe(
      map((response: Responsebody<T>) => {
        if (requestObj.message && requestObj.message.successMessage && requestObj.showToastr) {
          this.messageService.showSuccessMessage(requestObj.message.successMessage);
        }
        return response.Result;
      }) as OperatorFunction<Object, T> ,
      catchError((error) => {
        
        return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
      }));
  }
   
  /**
   * @description: Common get method
   * @returns: Return the generic response
   */
  get<T>(requestObj: RequestObj<T>): Observable<T> {

    return this.http.get(this.preRequestUrl + requestObj.uri).pipe(
      map((response: any) => {
        if (requestObj.message && requestObj.message.successMessage) {
          this.messageService.showSuccessMessage(requestObj.message.successMessage);
        }
        return response.Result;
      }),
      catchError((error) => {
        return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
      }));
  }

  /**
   *  @description: Common get method with custom error message
   *  @param uri: Pass the get url + query string parameters
   *  @returns: Return the generic response
   */
  getWithCustomErrorMessage<T>(uri: string): Observable<T> {
    return this.http.get(this.preRequestUrl + uri).pipe(
      map((response: Responsebody<T>) => {
        return response.Result;
      }) as OperatorFunction<Object, T>);
  }

  /**
   * @description: Common Post method
   * @returns: Returns the inserted object
   */
  post<T, R>(requestObj: RequestObj<T>): Observable<R> {
    
    return this.http.post(this.preRequestUrl + requestObj.uri, requestObj.object).pipe(
      map((response: Responsebody<R>) => {
        if (requestObj.message && requestObj.message.successMessage) {
          this.messageService.showSuccessMessage(requestObj.message.successMessage);
        }
        return response.Result;
      }) as OperatorFunction<Object, R>,
      catchError((error) => {   
        return this.errorHandlerService.handleError(error, requestObj.message  || null , requestObj.showToastr);
      }));
  }

  /**
   * @description: Post method with upload progress response
   * @returns: Returns the upload progress percentage or the inserted object
   */
  postWithProgress<T, R>(requestObj: RequestObj<T>): Observable<R> {
    return this.http.post(this.preRequestUrl + requestObj.uri, requestObj.object, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event: any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const percentDone = Math.round(100 * event.loaded / event.total);
            return percentDone;
          case HttpEventType.Response:
            if (requestObj.message && requestObj.message.successMessage) {
              this.messageService.showSuccessMessage(requestObj.message.successMessage);
            }
            return event.body.Result;
        }
      }) ,
      catchError((error) => {
        return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
      })
    );
  }
  /**
   * @description: Common Put method
   * @returns: Returns the updated object
   */
  put<T, R>(requestObj: RequestObj<T>): Observable<R> {

    return this.http.put(this.preRequestUrl + requestObj.uri, requestObj.object).pipe(
      map((response: Responsebody<R>) => {
        if (requestObj.message && requestObj.message.successMessage) {
          this.messageService.showSuccessMessage(requestObj.message.successMessage);
        }
        return response.Result;
      }) as OperatorFunction<Object, R>,
      catchError((error) => {
        return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
      }));
  }

  /**
   * @description: Common Delete method
   * @returns: Returns the generic type
   */
  delete<T>(requestObj: RequestObj<T>): Observable<T> {

    return this.http.delete(this.preRequestUrl + requestObj.uri, {body : requestObj.object}).pipe(
      map((response: Responsebody<T>) => {
        if (requestObj.message && requestObj.message.successMessage) {
          this.messageService.showSuccessMessage(requestObj.message.successMessage);
        }
        return response.Result;
      }) as OperatorFunction<Object, T>,
      catchError((error) => {
        return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
      }));
  }

  /**
   * @description: Common Delete method using body
   * @param uri: Pass the delete url + query string parameters
   * @param body: Pass the object for the delete
   * @param message: Pass custom error message if wanted to show custom error message
   * @returns: Returns the generic type
   */
  deleteWithBody<T, R>(requestObj: RequestObj<T>): Observable<R> {
    return this.http.request('delete', this.preRequestUrl + requestObj.uri, { body: requestObj.object }).pipe(
      map((response: Responsebody<R>) => {
        if (requestObj.message && requestObj.message.successMessage) {
          this.messageService.showSuccessMessage(requestObj.message.successMessage);
        }
        return response.Result;
      }) as OperatorFunction<Object, R>,
      catchError((error) => {
        return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
      }));
  }

  // /**
  //  * @description: Common resource upload method
  //  * @param url: Pass the upload url
  //  * @param formdata: Pass the upload file formdata name
  //  * @param message: Pass custom error message if wanted to show custom error message
  //  */
  // uploadResource<T>(requestObj: RequestObj<T>): Observable<any> {
  //   return this.http.post(this.preRequestUrl + requestObj.uri, requestObj.formdata, {
  //     reportProgress: true,
  //     observe: 'events',
  //     responseType: 'text'
  //   }).pipe(
  //     map((response) => {
  //       if (requestObj.message && requestObj.message.successMessage) {
  //         this.messageService.showSuccessMessage(requestObj.message.successMessage);
  //       }
  //       return response;
  //     }),
  //     catchError((error) => {
  //       return this.errorHandlerService.handleError(error,
  //   requestObj.message || null);
  //     }));
  // }

  /**
   * @description: Common resource download method
   * @param url: Pass the download resource url
   * @param message: Pass custom error message if wanted to show custom error message
   */
  downloadResource<T>(requestObj: RequestObj<T>): Observable<any> {
    return this.http.get(this.preRequestUrl + requestObj.uri, { responseType: 'blob' }).pipe(
      map((response) => {
        if (requestObj.message && requestObj.message.successMessage) {
          this.messageService.showSuccessMessage(requestObj.message.successMessage);
        }
        return response;
      }),
      catchError((error) => {
        return this.errorHandlerService.handleError(error, requestObj.message || null, requestObj.showToastr);
      }));
  }

  /**
   * @description get Secured Authorized resource data
   * @param url - Url to get resource
   * @returns logged in users profile data
   */
  getSecuredResource(uri: string): Observable<SafeUrl> {
    const imagePath = uri.replace('/User/GetUserImage/', '');
    const eTagKey = 'e-tag-' + imagePath;
    const existURL = this.cacheService.retrieve(eTagKey);

    const headers = new HttpHeaders();
    if (existURL) {      
      return of(existURL);
    }

    return this.http
      .get(this.preRequestUrl + uri, {
        headers,
        responseType: 'blob',
        observe: 'body',
      })
      .pipe(
        map((response) => {
          const bypassedURL = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(response as Blob)
          );
          this.cacheService.store(eTagKey, bypassedURL);
          return bypassedURL;
        })
      );

  }

  getExternalUrls(url:any) {
    return this.http.get(url);
  }

}
