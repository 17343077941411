import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { sideBarUser } from 'app/shared/constants/sideBarUserAccess';
import { LoggedInUser } from 'app/shared/interfaces/user.model';
import { CommonHttpService } from 'app/shared/services/common-http/common-http.service';
//for sidemenu structure
import { Menu, NavService } from 'app/shared/services/nav-service/nav.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit , AfterViewInit{

  sidebarMenu: Menu[];
  sidebarSubMenu?: Menu[];
  showSubSideBar: boolean = false;
  isSidebarCompact: boolean = false;
  windowWidth : number;
  isSideButtonActive : boolean = true;
  userPermission : number = 0;
  loggedInUser :any ;

  constructor(
    navService: NavService,
    private router: Router,
    private utilsService: UtilsService,
    private http: CommonHttpService
  ) {
    this.sidebarMenu = navService.MENUITEMS;
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        var activeTab = this.router.url;
        this.setActiveTab(activeTab);
        var activeTab = this.router.url;
        this.setActiveTab(activeTab);
      }
    });
  }
  ngAfterViewInit(): void {
    this.windowWidth = window.innerWidth;
    this.onResize(new Event(this.windowWidth.toString()));
  }
  // ngAfterViewInit(): void {
  //   this.windowWidth = window.innerWidth;
  //   this.onResize(new Event(this.windowWidth.toString()));
  // }

  ngOnInit(): void {
    // this.windowWidth = window.innerWidth;
    this.loggedInUser = JSON.parse(localStorage.getItem('logged_in_user') || '{}');
    this.CheckAndSetUserPermissions();
    var activeTab = this.router.url;
    this.setActiveTab(activeTab );
    var activeTab = this.router.url;
    this.setActiveTab(activeTab );
    this.utilsService.leftSideBarCompact.subscribe((val: boolean) => {
      this.isSidebarCompact = val;
    });
    // this.onResize(new Event(this.windowWidth.toString()));
  }

  CheckAndSetUserPermissions(){
    for(const key in sideBarUser){
      var sideBarSave = this.sidebarMenu.find(item => item.title === key);
      if(sideBarSave){
         if(sideBarUser[key].includes(this.loggedInUser.role.role) || sideBarUser[key].length === 0){
          sideBarSave.permission = true ;
          this.userPermission += 1 ;
         }
         else{
          sideBarSave.permission = false ;
         }
      }
    }
  }

  setActiveTab(tab: string | undefined ) {
    if (!tab) return;
    var tabArrPassed = tab.split('/');
    var activeUrl = '/'+tabArrPassed[1];
    if(tabArrPassed.length > 2){
      activeUrl += '/'+tabArrPassed[2] ;
    }
    var findSidenav = this.sidebarMenu.find((menu) => menu.path === activeUrl);
    if(findSidenav){
      this.sidebarMenu.find((menu) => menu.path == activeUrl)!.active = true;
    }else{
      activeUrl = '/'+tabArrPassed[1] ;
      findSidenav = this.sidebarMenu.find((menu) => menu.path === activeUrl);
      this.sidebarMenu.find((menu) => menu.path == activeUrl)!.active = true;
    }

    this.sidebarMenu.forEach((menu) => {
      if(menu.path == findSidenav?.path){
        menu.active = true;
      }else{
        menu.active = false;
      }
    })

    // this.removeSubLeftSidebar();
    // this.sidebarMenu.forEach(item => {
    //   if ('/' + tab == item.path || tab == item.path) {
    //     if(!item.Menusub){
    //       item.active = true;
    //     }else{
          
    //     }
    //   }
    //   else {
    //     item.active = false;
    //   }
    // });

    //for bottom side menu
    // this.sidebarMenu.forEach(item => {
    //   if (tab == item.path) {
    //     item.active = true;
    //   }
    //   else {
    //     item.active = false;
    //   }
    // });

  }

  // setActiveSubTab(tab: string | undefined) {
  //   if (tab != undefined) {
  //     tab = this.sidebarSubMenu?.find(x => x.path?.includes(tab!))?.path;
  //   }
  //   if (!tab) return;
  //   this.sidebarSubMenu?.forEach(item => {
  //     if (tab == item.path) {
  //       item.active = true;
  //     }
  //     else {
  //       item.active = false;
  //     }
  //   });
  // }
  // setActiveSubTab(tab: string | undefined) {
  //   if (tab != undefined) {
  //     tab = this.sidebarSubMenu?.find(x => x.path?.includes(tab!))?.path;
  //   }
  //   if (!tab) return;
  //   this.sidebarSubMenu?.forEach(item => {
  //     if (tab == item.path) {
  //       item.active = true;
  //     }
  //     else {
  //       item.active = false;
  //     }
  //   });
  // }

  @HostListener('window:load' , ['$event'])
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.windowWidth = window.innerWidth;
    if(this.windowWidth <= 991 ){
      var element = document.getElementById("left-sidebar");
      var checkCompact = element?.classList.contains("compact");
      if(!checkCompact) {
        if (element) element.classList.add("compact");
      }
      var elementMain = document.getElementById("main-container");
      var isMainContainerCompact = elementMain?.classList.contains("compact-left");
      if(!isMainContainerCompact) {
        if (elementMain) elementMain.classList.add("compact-left");
      }
      // this.utilsService.leftSideBarCompact.next(true);
      this.isSideButtonActive = false;
    }else{
      var element = document.getElementById("left-sidebar");
      var checkCompact = element?.classList.contains("compact");
      var elementMain = document.getElementById("main-container");
      var isMainContainerCompact = elementMain?.classList.contains("compact-left");
      if(checkCompact){
        element?.classList.remove("compact");
      }
      if(isMainContainerCompact){
        elementMain?.classList.remove("compact-left");
      }
      // this.utilsService.leftSideBarCompact.next(checkCompact);
      this.isSideButtonActive = true;
    }
  }

  toggleLeftSidebar() {
    var element = document.getElementById("left-sidebar");
    var checkCompact = element?.classList.contains("compact");
    if (element) element.classList.toggle("compact");
    var element = document.getElementById("main-container");
    if (element) element.classList.toggle("compact-left");
    // var element = document.getElementById("left-sub-sidebar");
    // if (element) element.classList.toggle("compact-sub-sidebar-left");
    this.utilsService.leftSideBarCompact.next(!checkCompact);
  }
  // toggleSubLeftSidebar() {
  //   var element = document.getElementById("main-container");
  //   if (element) element.classList.toggle("sub-sidebar-hidden");
  //   var element = document.getElementById("left-sub-sidebar");
  //   if (element) element.classList.toggle("sub-sidebar-hidden");
  // }
  // removeSubLeftSidebar() {
  //   var element = document.getElementById("main-container");
  //   if (element) element.classList.add("sub-sidebar-hidden");
  //   var element = document.getElementById("left-sub-sidebar");
  //   if (element) element.classList.add("sub-sidebar-hidden");
  // }
  // addSubLeftSidebar() {
  //   var element = document.getElementById("main-container");
  //   if (element) element.classList.remove("sub-sidebar-hidden");
  //   var element = document.getElementById("left-sub-sidebar");
  //   if (element) element.classList.remove("sub-sidebar-hidden");
  // }
  // setSubSidebarState() {
  //   var sidebar = document.getElementById("left-sidebar");
  //   if (sidebar?.classList?.contains('compact')) this.isSidebarCompact = true;
  // }
  redirectToMail(){
    window.location.href = 'mailto:naphs.helpdesk@who.int';
  }

  downloadUserGuide() {
    this.http.downloadResource({uri: `/User/GetUserGuide`}).subscribe((data : any) => {
      var blob = new Blob([data], {type: 'application/pdf'});
      var blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = this.getUserGuideName(2);
      a.click();
    }
    )
  }

  getUserGuideName(version : number = 1): string {
    const loggedInUser : LoggedInUser = JSON.parse(localStorage.getItem('logged_in_user') || '{}');    
    switch(loggedInUser.roleId){
      case 1: return `eNAPHS manual-System Admin-v${version}.0.pdf`;
      case 2: return `eNAPHS manual-Regional Admin-v${version}.0.pdf`;
      case 3: return `eNAPHS manual-Country Admin-v${version}.0.pdf`;
      case 4: return `eNAPHS manual-Country User-v${version}.0.pdf`;
      case 5: return `eNAPHS manual-Secretariat-v${version}.0.pdf`;
      case 6: return `eNAPHS manual-Global Viewer-v${version}.0.pdf`;
      case 7: return `eNAPHS manual-Country Viewer-v${version}.0.pdf`;
      default: return '';
    }
  }

}
